<template>
  <g>
    <svg:style>
     .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-width: 0.25px;
      }

      .cls-1, .cls-2, .cls-3 {
        stroke-miterlimit: 10;
      }

      .cls-1 {
        fill: url(#BG-gradient);
      }

      .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#inox-gradient-4);
      }

      .cls-8 {
        fill: url(#inox-gradient-5);
      }

      .cls-9 {
        fill: url(#inox-gradient-6);
      }
    </svg:style>

    <linearGradient id="handle-gradient" 
                    :x1="doorLeftWidth + 11.33" 
                    :y1="doorTopHeight + 150.87" 
                    :x2="doorLeftWidth + 17.01" 
                    :y2="doorTopHeight + 150.87" 
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient" 
                    :x1="doorLeftWidth + 40.53" 
                    :y1="doorTopHeight + 60.89" 
                    :x2="doorLeftWidth + 119.89" 
                    :y2="doorTopHeight + 60.89" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-2" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth + 40.53" 
                    :y1="doorTopHeight + 240.16" 
                    :x2="doorLeftWidth + 119.89" 
                    :y2="doorTopHeight + 240.16" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth + 40.53" 
                    :y1="doorTopHeight + 150.52" 
                    :x2="doorLeftWidth + 119.96" 
                    :y2="doorTopHeight + 150.52" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-4" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth + 40.53" 
                    :y1="doorTopHeight + 105.25" 
                    :x2="doorLeftWidth + 119.96" 
                    :y2="doorTopHeight + 105.25" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-5" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth + 40.53" 
                    :y1="doorTopHeight + 195.79" 
                    :x2="doorLeftWidth + 119.96" 
                    :y2="doorTopHeight + 195.79" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-6" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth + 39.62" 
                    :y1="inoxTop" 
                    :x2="doorLeftWidth + 39.62" 
                    :y2="doorTopHeight + 279.95" 
                    xlink:href="#handle-gradient"/>

    <g id="L23">
      <g v-if="showInox">
        <rect id="inox" 
              class="cls-4" 
              :x="doorLeftWidth + 40.53" 
              :y="doorTopHeight1 + 59.95" 
              :width="inoxW" 
              height="1.87"/>
        <rect id="inox-2" 
              data-name="inox" 
              class="cls-5" 
              :x="doorLeftWidth + 40.53" 
              :y="doorTopHeight1 + 239.22" 
              :width="inoxW" 
              height="1.87"/>
        <rect id="inox-3" 
              data-name="inox" 
              class="cls-6" 
              :x="doorLeftWidth + 40.53" 
              :y="doorTopHeight1 + 149.58" 
              :width="inoxW" 
              height="1.87"/>
        <rect id="inox-4" 
              data-name="inox" 
              class="cls-7" 
              :x="doorLeftWidth + 40.53" 
              :y="doorTopHeight1 + 104.31" 
              :width="inoxW" 
              height="1.87"/>
        <rect id="inox-5" 
              data-name="inox" 
              class="cls-8" 
              :x="doorLeftWidth + 40.53" 
              :y="doorTopHeight1 + 194.85" 
              :width="inoxW" 
              height="1.87"/>
        <rect id="inox-6" 
              data-name="inox" 
              class="cls-9" 
              :x="doorLeftWidth + 38.72" 
              :y="inoxTop" 
              width="1.81" 
              :height="inoxH"/>
      </g>
      
    </g>
  </g>
</template>


<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'showVariant',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
      leftOffset: 81,
    }
  },
  computed: {
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxH() {
      return this.showBg ?
        this.doorHeight - this.inoxOffset * 2 :
        this.doorHeight - this.leafOffset * 2
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - this.inoxOffset - this.leftOffset / 2 :
        this.doorWidth - this.leafOffset - this.leftOffset / 2
    },
    inoxTop() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    }
  }
}
</script>
